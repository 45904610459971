<template>
  <div v-if="permission.access">
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <b-card>
          <b-form-group label="Role Name" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
                name="name"
                placeholder="Enter Your Role Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card>
        <b-card>
          <b-card-title>Permissions</b-card-title>

          <div class="row">
            <div
              class="col-md-4 mb-2"
              v-for="(module, index) in modules"
              :key="index"
              v-if="modules.length"
            >
              <h5 class="mb-2">{{ module.name }}</h5>
              <b-form-checkbox-group v-model="form.permissions">
                <b-form-checkbox
                  class="mb-1 d-block"
                  v-for="(permission, per_index) in module.permissions"
                  :key="per_index"
                  v-if="module.permissions.length"
                  :value="permission.id"
                >
                  {{ permission.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </div>
        </b-card>
        <div class="d-flex justify-content-end mb-5">
          <b-button type="submit" variant="primary" @click="validationForm">
            Save
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BFormTextarea,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    quillEditor,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      snowOption: {
        minHeight: 200,
      },
      form: {
        name: "",
        permissions: [],
      },
      modules: {},
      permission: {
        access: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getModule();
    this.Edit();
  },
  methods: {
    async Edit() {
      const role = await this.callApi(
        "post",
        "/app/role/edit/" + this.$route.params.id
      );
      if (role.status == 200) {
        this.form.permissions = role.data.permission;
        this.form.name = role.data.role.name;
      }
    },
    async getModule() {
      const modules = await this.callApi(
        "post",
        "/app/permission/modules",
        this.search
      );
      if (modules.status == 200) {
        this.modules = modules.data.modules;
      }
    },
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "role.edit") {
          this.permission.access = true;
        }
      });
    },

    add() {
      axios
        .post("/app/role/update/" + this.$route.params.id, this.form)
        .then((res) => {
          this.s(res.data.message);
          this.$router.push("/roles");
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name) {
              this.e(e.response.data.errors.name[0]);
            }
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image_div {
  position: relative;
}
.image_delete {
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 30px;
  background: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  border-radius: 50%;
}
</style>
